import {
  Announcement as AnnouncementIcon,
  Input as InputIcon,
  Menu as MenuIcon,
} from '@material-ui/icons';
import {
  AppBar,
  Badge,
  Button,
  IconButton,
  Toolbar,
  Hidden,
} from '@material-ui/core';
import React from 'react';
import AnnounceKit from 'announcekit-react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import logo from '../../assets/img/npce.svg';
import { useAuth0 } from '../../utils/Auth0Provider';
import TopBarSearch from './TopBarSearch';

const TopBar = ({ onOpenNavBarMobile, className, ...rest }) => {
  const classes = useStyles();
  const { logout, user } = useAuth0();

  const handleLogout = () => {
    logout();
  };

  return (
    <AppBar {...rest} className={clsx(classes.root, className)} color="primary">
      <Toolbar>
        <IconButton
          className={classes.menuButton}
          color="inherit"
          onClick={onOpenNavBarMobile}
        >
          <MenuIcon />
        </IconButton>
        <RouterLink to="/">
          <img alt="Logo" src={logo} height={40} />
        </RouterLink>
        <div className={classes.flexGrow} />
        <Hidden smDown>
          <TopBarSearch />
          {/* release-notes badge - configured via announcekit site,
          currently hidden when there are no new notifications */}
          <IconButton
            color="inherit"
            className={clsx(classes.announceButton, 'ak-trigger')}
            data-testid="announcekit-button"
          >
            <Badge
              badgeContent={
                <AnnounceKit
                  catchClick=".ak-trigger"
                  widget="https://announcekit.app/widget/3V0hDq"
                  userData={user.email}
                />
              }
            >
              <AnnouncementIcon aria-label="notifications" />
            </Badge>
          </IconButton>
        </Hidden>
        <Hidden mdDown>
          <Button
            className={classes.logoutButton}
            color="inherit"
            onClick={handleLogout}
          >
            <InputIcon className={classes.logoutIcon} />
            Sign out
          </Button>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    boxShadow: 'none',
    backgroundColor: '#101A43',
  },
  flexGrow: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(1),
  },
  announceButton: {
    marginTop: '2px',
  },
  logoutButton: {
    marginLeft: theme.spacing(1),
  },
}));

TopBar.propTypes = {
  className: PropTypes.string,
  onOpenNavBarMobile: PropTypes.func,
};

export default TopBar;
