// unified label names - language specific

module.exports = {
  GENERIC_ERROR: {
    EN:
      'Oops! There was an error. Please try again or contact your NPCE Rep.',
    SP:
      '¡Discúlpenos! Hubo un error. Porfavor vuelva a intentar, o contacte a su representante de NPCE.',
  },
};
