import React, { lazy } from 'react';
import { Redirect } from 'react-router-dom';
import DashboardLayout from '../layouts/Dashboard';
import ErrorLayout from '../layouts/Error';
import Charges from '../views/Charges';
import AddNewCharge from '../views/Charges/AddCharge';
import Products from '../views/Products';
import AddNewProducts from '../views/Products/AddNewProducts';
import Customers from '../views/Customers';
import AddNewCustomer from '../views/Customers/AddNewCustomer';
import CustomerProfile from '../views/Customers/CustomerProfile';
import Records from '../views/Records';
import AddNewRecords from '../views/Records/AddNewRecords';
import RecordProfile from '../views/Records/Profile';

export default [
  {
    path: '/',
    exact: true,
    component: () => <Redirect to="/dashboard" />,
  },
  {
    path: '/errors',
    component: ErrorLayout,
    routes: [
      {
        path: '/errors/error-401',
        exact: true,
        component: lazy(() => import('../views/Error401')),
      },
      {
        path: '/errors/error-404',
        exact: true,
        component: lazy(() => import('../views/Error404')),
      },
      {
        component: () => <Redirect to="/errors/error-404" />,
      },
    ],
  },
  {
    route: '*',
    component: DashboardLayout,
    routes: [
      {
        path: '/dashboard',
        component: () => <h1>Dashboard</h1>,
      },
      {
        path: '/products',
        exact: true,
        component: () => <Products />
      },
      {
        path: '/products/new',
        exact: true,
        component: () => <AddNewProducts />
      },
      {
        path: '/charges',
        exact: true,
        component: () => <Charges />
      },
      {
        path: '/charges/new',
        exact: true,
        component: () => <AddNewCharge />
      },
      {
        path: '/customers',
        exact: true,
        component: () => <Customers />
      },
      {
        path: '/customers/new',
        exact: true,
        component: () => <AddNewCustomer />
      },
      {
        path: '/customers/:customerId',
        exact: true,
        component: props => <CustomerProfile {...props} />
      },
      {
        path: '/records',
        exact: true,
        component: () => <Records />
      },
      {
        path: '/records/:recordId',
        exact: true,
        component: props => <RecordProfile {...props} />
      },
      {
        path: '/records/new',
        exact: true,
        component: () => <AddNewRecords />
      }
    ],
  },
];
