import React from 'react';
import { ThemeProvider } from '@material-ui/styles';
import { CssBaseline } from '@material-ui/core';
import { Router } from 'react-router-dom';
import { renderRoutes } from 'react-router-config';
import { ToastContainer } from 'react-toastify';
import Themes from './themes';
import ScrollReset from './components/ScrollReset';
import GoogleAnalytics from './components/GoogleAnalytics';
import { useAuth0 } from './utils/Auth0Provider';
import RoutesConfig from './utils/RoutesConfig';
import history from './utils/history';
import Loader from './components/Loader';
import './App.css';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  const { loading } = useAuth0();

  if (loading) {
    return <Loader />;
  }

  return (
    <ThemeProvider theme={Themes.default}>
      <Router history={history}>
        <ScrollReset />
        <GoogleAnalytics />
        <CssBaseline />
        {renderRoutes(RoutesConfig)}
        <ToastContainer />
      </Router>
    </ThemeProvider>
  );
}

export default App;
