import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { useAuth0 } from '../../../utils/Auth0Provider';

// CC logos
import amexLogo from '../../../assets/img/icon_amex_card.svg';
import visaLogo from '../../../assets/img/icon_visa_card.svg';
import mastercardLogo from '../../../assets/img/icon_mastercard_card.svg';
import discoverLogo from '../../../assets/img/icon_discover_card.svg';

const CreditCardOnFile = () => {
  const { accessToken } = useAuth0();
  const { customerId } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [fetchError, setFetchError] = useState(false);
  const [paymentMethods, setPaymentMethods] = useState({});

  const getCustomerCC = useCallback(async () => {
    try {
      setIsLoading(true);
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/v1/customers/${customerId}/listPaymentMethods`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      setPaymentMethods(data);
    } catch (error) {
      setFetchError(true);
    } finally {
      setIsLoading(false);
    }
  }, [accessToken, customerId]);

  useEffect(() => {
    getCustomerCC();
  }, [getCustomerCC]);

  if (isLoading) {
    return <h3>Loading...</h3>;
  }

  if (fetchError) {
    return <h3>Uh Oh! Cannot get the information!</h3>;
  }

  return (
    <div>
      {paymentMethods.data &&
        paymentMethods.data.map((method) => {
          console.log('>>>', method);

          const imgSrc =
            method.card.brand === 'visa'
              ? visaLogo
              : method.card.brand === 'discover'
              ? discoverLogo
              : method.card.brand === 'mastercard'
              ? mastercardLogo
              : amexLogo;

          return (
            <span key={method.created}>
              <img
                src={imgSrc}
                alt={`${method.card.brand} CConFile`}
                height="35px"
              />
            </span>
          );
        })}
    </div>
  );
};

export default CreditCardOnFile;
