import React, { useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from '@stripe/react-stripe-js';

import {
  Button,
  Container,
  Grid,
  InputAdornment,
  Typography,
  TextField,
} from '@material-ui/core';
import { AccountBox } from '@material-ui/icons';
import Page from '../../../components/Page';
import { useAuth0 } from '../../../utils/Auth0Provider';

const StripeInput = (props) => {
  const { component: Component, inputRef, ...other } = props;
  const elementRef = useRef();

  React.useImperativeHandle(inputRef, () => ({
    focus: () => elementRef.current.focus,
  }));

  return (
    <Component
      onReady={(element) => (elementRef.current = element)}
      {...other}
    />
  );
};

const AddPaymentMethodForm = (props) => {
  const { customerEmail, closeModal } = props;
  const { accessToken } = useAuth0();
  const { customerId } = useParams();
  const stripe = useStripe();
  const elements = useElements();
  const { register, handleSubmit, errors, reset } = useForm();
  const [checkoutError, setCheckoutError] = useState(false);
  const [isProcessing, setProcessingTo] = useState(false);

  const handleCardDetailsChange = (ev) =>
    ev.error ? setCheckoutError(ev.error.message) : setCheckoutError();

  const hasError = checkoutError !== false;

  const handleOnSubmit = async (formData) => {
    setProcessingTo(true);
    const cardElement = elements.getElement('cardNumber');

    try {
      const { paymentMethod } = await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement,
        billing_details: {
          name: `${formData.firstName} ${formData.lastName}`,
          email: customerEmail,
        },
      });

      if (paymentMethod.error) {
        setCheckoutError(paymentMethod.error.message);
        return;
      }

      await axios.post(
        `${process.env.REACT_APP_API_URL}/v1/customers/${customerId}/attachPaymentSource`,
        {
          paymentMethodId: paymentMethod.id,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      closeModal();
    } catch (error) {
      setCheckoutError(error.message);
    } finally {
      setProcessingTo(false);
      reset();
    }
  };

  return (
    <Page title="Add new payment method">
      <Container maxWidth={false}>
        <Typography variant="button">Add New Payment Method</Typography>
        <form onSubmit={handleSubmit(handleOnSubmit)}>
          <Grid container spacing={4}>
            <Grid item xs={6}>
              <TextField
                label="First Name on the Card"
                name="firstName"
                inputRef={register({
                  required: true,
                  message: 'First Name is required',
                })}
                InputProps={{
                  'aria-label': 'First Name',
                  startAdornment: (
                    <InputAdornment position="start">
                      <AccountBox />
                    </InputAdornment>
                  ),
                }}
                fullWidth
                variant="outlined"
                margin="normal"
                style={{ marginRight: 8 }}
                defaultValue=""
                error={errors.firstName && errors.firstName.type === 'required'}
                helperText={errors.firstName && <span>Required</span>}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Last Name on the Card"
                name="lastName"
                inputRef={register({
                  required: true,
                  message: 'Last Name is required',
                })}
                InputProps={{
                  'aria-label': 'Last Name',
                  startAdornment: (
                    <InputAdornment position="start">
                      <AccountBox />
                    </InputAdornment>
                  ),
                }}
                fullWidth
                variant="outlined"
                margin="normal"
                style={{ marginRight: 8 }}
                defaultValue=""
                error={errors.lastName && errors.lastName.type === 'required'}
                helperText={errors.lastName && <span>Required</span>}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                variant="outlined"
                margin="none"
                label="Credit Card Number"
                error={hasError}
                helperText={hasError && checkoutError}
                onChange={handleCardDetailsChange}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  inputProps: {
                    component: CardNumberElement,
                  },
                  inputComponent: StripeInput,
                }}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                fullWidth
                variant="outlined"
                margin="none"
                label="Exp Date"
                error={hasError}
                helperText={hasError && checkoutError}
                onChange={handleCardDetailsChange}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  inputProps: {
                    component: CardExpiryElement,
                  },
                  inputComponent: StripeInput,
                }}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                fullWidth
                variant="outlined"
                margin="none"
                label="CVC"
                error={hasError}
                helperText={hasError && checkoutError}
                onChange={handleCardDetailsChange}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  inputProps: {
                    component: CardCvcElement,
                  },
                  inputComponent: StripeInput,
                }}
              />
            </Grid>
            <Button
              type="submit"
              variant="contained"
              size="medium"
              color="primary"
              disabled={isProcessing || !stripe}
              fullWidth
            >
              Add Now
            </Button>
          </Grid>
        </form>
      </Container>
    </Page>
  );
};

export default AddPaymentMethodForm;
