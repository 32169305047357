import React, { useCallback, useEffect, useState } from 'react';
import { Button, Container, Typography } from '@material-ui/core';
import MaterialTable from 'material-table';
import axios from 'axios';
import { toast } from 'react-toastify';
import Page from '../../components/Page';
import Loader from '../../components/Loader';
import { useAuth0 } from '../../utils/Auth0Provider';

const Products = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [productsList, setProductsList] = useState([]);
  const [fetchError, setFetchError] = useState(false);

  const { accessToken } = useAuth0();

  const columns = [
    {
      title: 'Name',
      field: 'productName',
    },
    {
      title: 'Price',
      field: 'phoneNumber',
      render: (rowData) => (
        <span>
          {(rowData.amount / 100).toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
          })}
        </span>
      ),
    },
    {
      title: 'Notes',
      field: 'notes',
    },
    {
      title: '',
      filtering: false,
      width: 100,
      render: ({ productId }) => (
        <Button
          color="warning"
          onClick={() => deleteProduct(productId)}
          size="small"
          style={{ marginRight: 5 }}
          variant="contained"
        >
          Delete
        </Button>
      ),
    },
  ];

  const deleteProduct = async (productId) => {
    try {
      const { data } = await axios.delete(
        `${process.env.REACT_APP_API_URL}/v1/products/${productId}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      toast.success(data.msg);
    } catch (error) {
      toast.error(error.msg);
    } finally {
      getProductList();
    }
  };

  const getProductList = useCallback(() => {
    setIsLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}/v1/products`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then(({ data }) => setProductsList(data))
      .catch(() => setFetchError(true))
      .finally(() => setIsLoading(false));
  }, [accessToken]);

  useEffect(() => {
    getProductList();
  }, [getProductList]);

  return (
    <Page title="Products">
      <Container maxWidth={false}>
        <MaterialTable
          filtering
          isLoading={isLoading}
          columns={columns}
          data={productsList}
          errorText={fetchError && 'Cannot get data'}
          title={<Typography variant="h2">Products</Typography>}
          localization={{
            header: {
              actions: '',
            },
          }}
          components={{
            OverlayLoading: () => <Loader />,
          }}
        />
      </Container>
    </Page>
  );
};

export default Products;
