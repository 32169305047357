import {
  Avatar,
  Divider,
  Drawer,
  Grid,
  Hidden,
  List,
  ListSubheader,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
/* eslint-disable react/no-multi-comp */
import React, { useEffect } from 'react';
import { matchPath, useLocation } from 'react-router-dom';

import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import NavItem from '../../components/NavItem';
import navConfig from './navConfig';
import { useAuth0 } from '../../utils/Auth0Provider';

// renderNavItems calls itself recursively when a menu item has sub-items,
// creating the sub-items and automatically increasing the padding to
// give a nested appearance (i.e. depth), it also adds
// the open/close functionality for these sub-item dropdowns..
const renderNavItems = ({ items, subheader, key = 0, ...rest }) => {
  return (
    <List key={key}>
      {subheader && (
        <ListSubheader disableSticky>{subheader.toUpperCase()}</ListSubheader>
      )}
      {/* eslint-disable-next-line react/prop-types */}
      {items.reduce(
        // eslint-disable-next-line no-use-before-define
        (acc, item) => reduceChildRoutes({ acc, item, ...rest }),
        []
      )}
    </List>
  );
};

const reduceChildRoutes = ({ acc, pathname, item, depth = 0 }) => {
  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
    });

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        key={item.title}
        label={item.label}
        open={Boolean(open)}
        title={item.title}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items,
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        key={item.title}
        label={item.label}
        title={item.title}
      />
    );
  }

  return acc;
};

const NavBar = ({ openMobile, onMobileClose, className, ...rest }) => {
  const { user } = useAuth0();
  const classes = useStyles();
  const location = useLocation();
  const isMobile = useMediaQuery('(max-width:1279px)');

  useEffect(() => {
    if (isMobile) {
      onMobileClose();
    }
    // eslint-disable-next-line
  }, [location.pathname, isMobile]);

  const content = (
    <div {...rest} className={clsx(classes.root, className)}>
      <nav className={classes.navigation}>
        {navConfig.map((list) =>
          renderNavItems({
            items: list.items,
            subheader: list.subheader,
            pathname: location.pathname,
            key: list.subheader,
          })
        )}
      </nav>
      <Divider className={classes.divider} />
      <div className={classes.profile}>
        <Grid container direction="row" alignItems="center" spacing={2}>
          <Grid item>
            <Avatar
              alt="Person"
              className={classes.avatar}
              src={user.picture}
            />
          </Grid>
          <Grid item>
            <Typography variant="h5">{user.name}</Typography>
          </Grid>
          {/* TODO: Add company name of user to their auth token */}
          {/* <Typography variant="body2">{user.company}</Typography> */}
        </Grid>
      </div>
    </div>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{
            paper: classes.mobileDrawer,
          }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{
            paper: classes.desktopDrawer,
          }}
          onClose={onMobileClose}
          open={openMobile}
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  mobileDrawer: {
    width: 256,
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)',
  },
  navigation: {
    overflow: 'auto',
    padding: theme.spacing(0, 2, 2, 2),
    flexGrow: 1,
  },
  profile: {
    padding: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
  },
  avatar: {
    width: 40,
    height: 40,
  },
}));

NavBar.propTypes = {
  className: PropTypes.string,
  subheader: PropTypes.string,
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

export default NavBar;
