import './stripeStyles.css';

import React, { useCallback, useEffect, useState } from 'react';
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Container,
  InputAdornment,
  TextField,
  Typography,
} from '@material-ui/core';
import { Telegram, Call } from '@material-ui/icons';
import axios from 'axios';
import StripeInput from './StripeInput';
import HeartBeatLoader from '../../../components/HeartbeatLoader';
import Page from '../../../components/Page';
import { useAuth0 } from '../../../utils/Auth0Provider';

const emailMatchRegex = /\S+@\S+\.\S+/;

const AddNewChargeForm = () => {
  const { accessToken } = useAuth0();
  const stripe = useStripe();
  const elements = useElements();
  const { register, handleSubmit, errors, reset } = useForm();

  const [values, setValues] = useState({
    customerId: undefined,
    productId: undefined,
    customerNotes: undefined,
    email: undefined,
    phoneNumber: undefined,
    addressLine1: undefined,
    addressLine2: undefined,
    city: undefined,
    state: undefined,
    postal_code: undefined,
  });

  const handleChange = (event) =>
    setValues({
      ...values,
      [event.target.name]:
        event.target.type === 'checkbox'
          ? event.target.checked
          : event.target.value,
    });

  const [customersList, setCustomersList] = useState([]);
  const [isCustomerListLoading, setIsCustomerListLoading] = useState(false);

  const [productsList, setProductsList] = useState([]);
  const [isProductListLoading, setIsProductListLoading] = useState(false);

  const getAvailableCustomers = useCallback(() => {
    setIsCustomerListLoading(true);

    axios
      .get(`${process.env.REACT_APP_API_URL}/v1/customers`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then(({ data }) => setCustomersList(data))
      .catch((err) => toast.error(err))
      .finally(() => setIsCustomerListLoading(false));
  }, [accessToken]);

  const getAvailableProducts = useCallback(() => {
    setIsProductListLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}/v1/products`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then(({ data }) => setProductsList(data))
      .catch((err) => toast.error(err))
      .finally(() => setIsProductListLoading(false));
  }, [accessToken]);

  const handleOnSubmit = async (formData) => {
    if (!stripe || !elements) {
      return;
    }

    const cardElement = elements.getElement(CardNumberElement);

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
      billing_details: {
        address: {
          line1: values.addressLine1,
          line2: values.addressLine2,
          city: values.city,
          state: values.state,
          postal_code: values.postal_code,
        },
        email: values.email,
        phone: values.phoneNumber,
      },
      metadata: {
        notes: values.customerNotes,
      },
    });

    console.group('Stripe');
    console.log('>>> cardElement', cardElement);
    console.log('>>> formData', formData);
    console.log('>>> values', values);
    console.log('>>> error', error);
    console.log('>>> paymentMethod', paymentMethod);
    console.groupEnd();
  };

  useEffect(() => {
    getAvailableCustomers();
    getAvailableProducts();
  }, [getAvailableCustomers, getAvailableProducts]);

  return (
    <Page title="Add a Charge ">
      <Container maxWidth={false}>
        <Card>
          <CardHeader
            title={<Typography variant="h2">Add a Charge</Typography>}
          />
          <CardContent>
            <form onSubmit={handleSubmit(handleOnSubmit)}>
              <Grid container spacing={4}>
                <Grid item xs={2}>
                  {isCustomerListLoading ? (
                    <HeartBeatLoader />
                  ) : (
                    <TextField
                      fullWidth
                      label="Customer"
                      name="customerId"
                      inputRef={register()}
                      onChange={handleChange}
                      select
                      // eslint-disable-next-line react/jsx-sort-props
                      SelectProps={{ native: true }}
                      value={values.customerId}
                      variant="outlined"
                    >
                      {customersList.map((customer) => (
                        <option
                          key={customer.customerId}
                          value={customer.customerId}
                        >
                          {customer.fullName}
                        </option>
                      ))}
                    </TextField>
                  )}
                </Grid>
                <Grid item xs={2}>
                  {isProductListLoading ? (
                    <HeartBeatLoader />
                  ) : (
                    <TextField
                      fullWidth
                      label="Product"
                      name="productId"
                      inputRef={register}
                      onChange={handleChange}
                      select
                      // eslint-disable-next-line react/jsx-sort-props
                      SelectProps={{ native: true }}
                      value={values.customerId}
                      variant="outlined"
                    >
                      {productsList.map((product) => (
                        <option
                          key={product.productId}
                          value={product.productId}
                        >
                          {product.productName}
                        </option>
                      ))}
                    </TextField>
                  )}
                </Grid>

                <Grid item xs={4}>
                  <TextField
                    label="Credit Card Number"
                    name="ccnumber"
                    variant="outlined"
                    required
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      inputComponent: StripeInput,
                      inputProps: {
                        component: CardNumberElement,
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={2}>
                  <TextField
                    label="Expiration Date"
                    name="ccexp"
                    variant="outlined"
                    required
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      inputComponent: StripeInput,
                      inputProps: {
                        component: CardExpiryElement,
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={2}>
                  <TextField
                    label="CVC"
                    name="cvc"
                    variant="outlined"
                    required
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      inputComponent: StripeInput,
                      inputProps: {
                        component: CardCvcElement,
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="Street Address 1"
                    inputRef={register({
                      required: true,
                      message: 'Address is required',
                    })}
                    fullWidth
                    name="addressLine1"
                    onChange={handleChange}
                    defaultValue={values.addressLine1}
                    style={{ marginRight: 8 }}
                    variant="outlined"
                    margin="normal"
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="Street Address 2 (optional)"
                    inputRef={register()}
                    fullWidth
                    name="addressLine2"
                    onChange={handleChange}
                    defaultValue={values.addressLine2}
                    style={{ marginRight: 8 }}
                    variant="outlined"
                    margin="normal"
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    label="City"
                    inputRef={register()}
                    fullWidth
                    name="city"
                    onChange={handleChange}
                    defaultValue={values.city}
                    style={{ marginRight: 8 }}
                    variant="outlined"
                    margin="normal"
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    label="State"
                    inputRef={register()}
                    fullWidth
                    name="state"
                    onChange={handleChange}
                    defaultValue={values.state}
                    style={{ marginRight: 8 }}
                    variant="outlined"
                    margin="normal"
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    label="ZIP"
                    inputRef={register()}
                    fullWidth
                    name="postal_code"
                    onChange={handleChange}
                    defaultValue={values.postal_code}
                    style={{ marginRight: 8 }}
                    variant="outlined"
                    margin="normal"
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="E-Mail"
                    name="email"
                    inputRef={register({
                      required: true,
                      message: 'Email is required',
                      pattern: emailMatchRegex,
                    })}
                    InputProps={{
                      'aria-label': 'Email',
                      startAdornment: (
                        <InputAdornment position="start">
                          <Telegram />
                        </InputAdornment>
                      ),
                    }}
                    fullWidth
                    variant="outlined"
                    margin="normal"
                    style={{ marginRight: 8 }}
                    defaultValue={values.email}
                    onChange={handleChange}
                    error={
                      errors.email &&
                      (errors.email.type === 'required' ||
                        errors.email.type === 'pattern')
                    }
                    helperText={
                      errors.email && errors.email.type === 'pattern' ? (
                        <span>Email does not match</span>
                      ) : (
                        <span>Required</span>
                      )
                    }
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="Phone #"
                    name="phoneNumber"
                    inputRef={register({
                      required: true,
                      message: 'Phone number is required',
                    })}
                    InputProps={{
                      'aria-label': 'Phone Number',
                      startAdornment: (
                        <InputAdornment position="start">
                          <Call />
                        </InputAdornment>
                      ),
                    }}
                    fullWidth
                    variant="outlined"
                    margin="normal"
                    style={{ marginRight: 8 }}
                    defaultValue={values.phoneNumber}
                    onChange={handleChange}
                    error={
                      errors.phoneNumber &&
                      errors.phoneNumber.type === 'required'
                    }
                    helperText={errors.phoneNumber && <span>Required</span>}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="outlined-multiline-static"
                    label="Notes"
                    name="customerNotes"
                    inputRef={register()}
                    inputProps={{
                      'aria-label': 'Notes about customer',
                    }}
                    onChange={handleChange}
                    multiline
                    fullWidth
                    rows={4}
                    variant="outlined"
                    style={{ marginRight: 8 }}
                    defaultValue={values.customerNotes}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button type="submit" variant="contained" color="primary">
                    Pay
                  </Button>
                </Grid>
              </Grid>
            </form>
          </CardContent>
        </Card>
      </Container>
    </Page>
  );
};

export default AddNewChargeForm;
