import React from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import AddChargeForm from './AddChargeForm';

const stripePromise = loadStripe(process.env.REACT_APP_NPCE_API_KEY);

const AddChargePage = () => {
  return (
    <Elements stripe={stripePromise}>
      <AddChargeForm />
    </Elements>
  );
};

export default AddChargePage;
