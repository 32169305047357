import React, { Suspense, useState } from 'react';

import { LinearProgress } from '@material-ui/core';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { renderRoutes } from 'react-router-config';
import TopBar from './TopBar';
import NavBar from './NavBar';
import { useAuth0 } from '../../utils/Auth0Provider';

const Dashboard = ({ route }) => {
  const classes = useStyles();
  const [openNavBarMobile, setOpenNavBarMobile] = useState(true);
  const { isAuthenticated, loginWithRedirect } = useAuth0();

  return !isAuthenticated ? (
    loginWithRedirect({})
  ) : (
    <>
      <TopBar
        onOpenNavBarMobile={() => setOpenNavBarMobile(!openNavBarMobile)}
      />
      <NavBar
        onMobileClose={() => setOpenNavBarMobile(false)}
        openMobile={openNavBarMobile}
      />

      <div className={classes.container}>
        <div
          className={`${classes.content} ${
            openNavBarMobile && classes.contentDesktopPadding
          }`}
        >
          <Suspense fallback={<LinearProgress />}>
            {renderRoutes(route.routes)}
          </Suspense>
        </div>
      </div>
    </>
  );
};

const drawerWidth = 256;

const useStyles = makeStyles((theme) => ({
  container: {
    minHeight: '100vh',
    display: 'flex',
    '@media all and (-ms-high-contrast:none)': {
      height: 0, // IE11 fix
    },
  },
  content: {
    paddingTop: 64,
    flexGrow: 1,
    maxWidth: '100%',
    overflowX: 'hidden',
    [theme.breakpoints.down('xs')]: {
      paddingTop: 56,
    },
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },

  contentDesktopPadding: {
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
    [theme.breakpoints.up('lg')]: {
      width: `calc(100% - ${drawerWidth}px - 40px)`,
    },
    [theme.breakpoints.up('lg')]: {
      marginLeft: drawerWidth,
    },
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
}));

Dashboard.propTypes = {
  route: PropTypes.shape({}),
};

export default Dashboard;
