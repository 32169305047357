import {
  PeopleOutline as CustomersIcon,
  AttachMoneyOutlined as ChargesIcon,
  QuestionAnswerOutlined as SmsIcon,
  Store,
} from '@material-ui/icons';

export default [
  {
    items: [
      {
        title: 'Charges',
        icon: ChargesIcon,
        items: [
          {
            title: 'Existing Charges',
            href: '/charges',
          },
          {
            title: 'Add New',
            href: '/charges/new',
          },
        ],
      },
      {
        title: 'Products',
        icon: Store,
        items: [
          {
            title: 'Products',
            href: '/products',
          },
          {
            title: 'Add New',
            href: '/products/new',
          },
        ],
      },
      {
        title: 'Customers',
        icon: CustomersIcon,
        items: [
          {
            title: 'All Customers',
            href: '/customers',
          },
          {
            title: 'Add New',
            href: '/customers/new',
          },
        ],
      },
      {
        title: 'Records',
        icon: SmsIcon,
        items: [
          {
            title: 'All Records',
            href: '/records',
          },
          {
            title: 'Add New',
            href: '/records/new',
          },
        ],
      },
    ],
  },
];
