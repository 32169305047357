import React, { useCallback, useState, useEffect, Suspense } from 'react';
import {
  Box,
  Button,
  Chip,
  Grid,
  Card,
  CardContent,
  CardHeader,
  Dialog,
  TextField,
  Typography,
} from '@material-ui/core';
import axios from 'axios';
import { Link, useParams } from 'react-router-dom';
import { Done } from '@material-ui/icons';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import Page from '../../../components/Page';
import Loader from '../../../components/Loader';
import HeartBeatLoader from '../../../components/HeartbeatLoader';
import { useAuth0 } from '../../../utils/Auth0Provider';
import CreditCardOnFile from './CreditCardsOnFile';
import AddPaymentMethodForm from './AddPaymentMethodForm';

const CustomerProfile = () => {
  const { accessToken } = useAuth0();
  const { customerId } = useParams();
  const [openAddPaymentDialog, setOpenAddPaymentDialog] = React.useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [fetchError, setFetchError] = useState(false);
  const [customerData, setCustomerData] = useState({});

  const stripePromise = loadStripe(process.env.REACT_APP_NPCE_API_KEY);

  const getCustomerData = useCallback(async () => {
    try {
      setIsLoading(true);
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/v1/customers/${customerId}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      setCustomerData(data);
    } catch (error) {
      setFetchError(true);
    } finally {
      setIsLoading(false);
    }
  }, [accessToken, customerId]);

  const handleAddPaymentMethodOpen = () => setOpenAddPaymentDialog(true);
  const handleAddPaymentMethodClose = () => setOpenAddPaymentDialog(false);

  useEffect(() => {
    getCustomerData();
  }, [getCustomerData]);

  const { id, fullName, phoneNumber, email, isNPCECustomer } = customerData;

  if (isLoading) {
    return <Loader />;
  }

  if (fetchError) {
    return <h3>Cannot get data!</h3>;
  }

  return (
    <Page title="Customer Profile">
      <Box m={3}>
        <Card raised>
          <CardHeader
            action={
              <>
                <Button
                  onClick={handleAddPaymentMethodOpen}
                  variant="contained"
                  color="secondary"
                >
                  Add Payment Method
                </Button>
                <Button
                  to="/customers"
                  component={Link}
                  variant="contained"
                  color="secondary"
                >
                  X
                </Button>
              </>
            }
            title={fullName}
            subheader={
              isNPCECustomer && (
                <Chip
                  label="NPCE"
                  icon={<Done />}
                  color="primary"
                  size="small"
                />
              )
            }
          />
          <CardContent>
            <Grid container spacing={4}>
              <Grid item xs={3}>
                <TextField
                  id="filled-read-only-input"
                  label="Phone #"
                  value={phoneNumber || 'N/A'}
                  InputProps={{
                    readOnly: true,
                  }}
                  variant="outlined"
                  margin="dense"
                  fullWidth
                  style={{ marginRight: 8 }}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  id="filled-read-only-input"
                  label="E-Mail"
                  value={email || 'N/A'}
                  InputProps={{
                    readOnly: true,
                  }}
                  variant="outlined"
                  margin="dense"
                  fullWidth
                  style={{ marginRight: 8 }}
                />
              </Grid>
              <Grid item xs={3}>
                <Typography variant="button">Credit Card on File</Typography>
                <Suspense fallback={<HeartBeatLoader />}>
                  <CreditCardOnFile customerId={id} />
                </Suspense>
              </Grid>
            </Grid>
            <Dialog
              open={openAddPaymentDialog}
              onClose={handleAddPaymentMethodClose}
            >
              <Elements stripe={stripePromise}>
                <AddPaymentMethodForm
                  customerEmail={email}
                  closeModal={handleAddPaymentMethodClose}
                />
              </Elements>
            </Dialog>
          </CardContent>
        </Card>
      </Box>
    </Page>
  );
};

export default CustomerProfile;
