import React, { useEffect, useState } from 'react';
import { Container, Typography } from '@material-ui/core';
import MaterialTable from 'material-table';
import axios from 'axios';
import Page from '../../components/Page';
import Loader from '../../components/Loader';
import { useAuth0 } from '../../utils/Auth0Provider';

const Charges = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [chargesList, setChargesList] = useState([]);
  const [fetchError, setFetchError] = useState(false);

  const { accessToken } = useAuth0();

  const columns = [];

  useEffect(() => {
    setIsLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}/v1/charges`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then(({ data }) => setChargesList(data))
      .catch(() => setFetchError(true))
      .finally(() => setIsLoading(false));
  }, [accessToken]);

  return (
    <Page title="Charges">
      <Container maxWidth={false}>
        <MaterialTable
          filtering
          isLoading={isLoading}
          columns={columns}
          data={chargesList}
          errorText={fetchError && 'Cannot get data'}
          title={<Typography variant="h2">Charges</Typography>}
          localization={{
            header: {
              actions: '',
            },
          }}
          components={{
            OverlayLoading: () => <Loader />,
          }}
        />
      </Container>
    </Page>
  );
};

export default Charges;
