import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Container, Typography } from '@material-ui/core';
import MaterialTable from 'material-table';
import axios from 'axios';
import Page from '../../components/Page';
import Loader from '../../components/Loader';
import { useAuth0 } from '../../utils/Auth0Provider';

const Customers = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [customersList, setCustomersList] = useState([]);
  const [fetchError, setFetchError] = useState(false);

  const { accessToken } = useAuth0();

  const columns = [
    {
      title: '',
      filtering: false,
      width: 100,
      render: ({ customerId }) => (
        <Button
          color="primary"
          component={Link}
          size="small"
          style={{ marginRight: 5 }}
          to={`/customers/${customerId}`}
          variant="contained"
        >
          View
        </Button>
      ),
    },
    {
      title: 'Full Name',
      field: 'fullName',
    },
    {
      title: 'Phone #',
      field: 'phoneNumber',
    },
    {
      title: 'E-Mail',
      field: 'email',
    },
  ];

  useEffect(() => {
    setIsLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}/v1/customers`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then(({ data }) => setCustomersList(data))
      .catch(() => setFetchError(true))
      .finally(() => setIsLoading(false));
  }, [accessToken]);

  return (
    <Page title="Customers">
      <Container maxWidth={false}>
        <MaterialTable
          filtering
          isLoading={isLoading}
          columns={columns}
          data={customersList}
          errorText={fetchError && 'Cannot get data'}
          title={<Typography variant="h2">Customers</Typography>}
          localization={{
            header: {
              actions: '',
            },
          }}
          components={{
            OverlayLoading: () => <Loader />,
          }}
        />
      </Container>
    </Page>
  );
};

export default Customers;
