import React from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Container,
  InputAdornment,
  TextField,
  Typography,
} from '@material-ui/core';
import { Store, AttachMoney } from '@material-ui/icons';
import axios from 'axios';
import Page from '../../../components/Page';
import { useAuth0 } from '../../../utils/Auth0Provider';

const AddNewProduct = () => {
  const { accessToken } = useAuth0();

  const { register, handleSubmit, errors, reset } = useForm();

  const handleOnSubmit = (formData) => {
    return axios
      .post(`${process.env.REACT_APP_API_URL}/v1/products`, formData, {
        headers: { Authorization: `Bearer ${accessToken}` },
      })
      .then(({ data }) => {
        if (data.customerExists) {
          return toast.error(data.msg);
        }

        return toast.success(data.msg);
      })
      .finally(() => {
        reset();
      });
  };

  return (
    <Page title="Add a Product">
      <Container maxWidth={false}>
        <Card marginTop={3}>
          <CardHeader
            title={<Typography variant="h2">Add a Product</Typography>}
          />
          <CardContent>
            <form onSubmit={handleSubmit(handleOnSubmit)}>
              <Grid container spacing={4}>
                <Grid item xs={6}>
                  <TextField
                    label="Product Name"
                    name="productName"
                    inputRef={register({
                      required: true,
                      message: 'Product name is required',
                    })}
                    InputProps={{
                      'aria-label': 'Product',
                      startAdornment: (
                        <InputAdornment position="start">
                          <Store />
                        </InputAdornment>
                      ),
                    }}
                    fullWidth
                    variant="outlined"
                    margin="normal"
                    style={{ marginRight: 8 }}
                    defaultValue=""
                    error={
                      errors.productName &&
                      errors.productName.type === 'required'
                    }
                    helperText={errors.productName && <span>Required</span>}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="Amount"
                    name="productAmount"
                    inputRef={register({
                      required: true,
                      message: 'Amount is required',
                    })}
                    InputProps={{
                      'aria-label': 'Product amount',
                      startAdornment: (
                        <InputAdornment position="start">
                          <AttachMoney />
                        </InputAdornment>
                      ),
                    }}
                    fullWidth
                    variant="outlined"
                    margin="normal"
                    style={{ marginRight: 8 }}
                    defaultValue=""
                    error={
                      errors.productAmount &&
                      errors.productAmount.type === 'required'
                    }
                    helperText={errors.productAmount && <span>Required</span>}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="outlined-multiline-static"
                    label="Notes"
                    name="productNotes"
                    inputRef={register()}
                    inputProps={{
                      'aria-label': 'Notes about a product',
                    }}
                    multiline
                    fullWidth
                    rows={4}
                    variant="outlined"
                    style={{ marginRight: 8 }}
                    defaultValue=""
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button type="submit" variant="contained" color="primary">
                    Add a Product
                  </Button>
                </Grid>
              </Grid>
            </form>
          </CardContent>
        </Card>
      </Container>
    </Page>
  );
};

export default AddNewProduct;
