import React, { useCallback, useState, useEffect, Suspense } from 'react';
import { Grid, Container, TextField } from '@material-ui/core';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import Page from '../../../components/Page';
import HeartBeatLoader from '../../../components/HeartbeatLoader';
import { useAuth0 } from '../../../utils/Auth0Provider';

const RecordProfile = () => {
  const { accessToken } = useAuth0();
  const { recordId } = useParams();
  const [fetchError, setFetchError] = useState(false);
  const [recordData, setRecordData] = useState({});

  const getRecordData = useCallback(async () => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/v1/records/${recordId}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      setRecordData(data);
    } catch (error) {
      setFetchError(true);
    }
  }, [accessToken, recordId]);

  useEffect(() => {
    getRecordData();
  }, [getRecordData]);

  const { fullName, phoneNumber, email } = recordData;

  if (fetchError) {
    return <h3>Cannot get data!</h3>;
  }

  return (
    <Page title="Record Profile">
      <Container maxWidth={false}>
        <Suspense fallback={<HeartBeatLoader />}>
          <Grid container spacing={4}>
            <TextField
              id="filled-read-only-input"
              label="Full Name"
              value={fullName || 'N/A'}
              InputProps={{
                readOnly: true,
              }}
              variant="outlined"
              margin="dense"
            />
            <TextField
              id="filled-read-only-input"
              label="Phone #"
              value={phoneNumber || 'N/A'}
              InputProps={{
                readOnly: true,
              }}
              variant="outlined"
              margin="dense"
            />
            <TextField
              id="filled-read-only-input"
              label="E-Mail"
              value={email || 'N/A'}
              InputProps={{
                readOnly: true,
              }}
              variant="outlined"
              margin="dense"
            />
          </Grid>
        </Suspense>
      </Container>
    </Page>
  );
};

export default RecordProfile;
