import React, { useCallback } from 'react';
import { Card, CardContent, Container } from '@material-ui/core';
import Dropzone from 'react-dropzone';
import axios from 'axios';

import csv from 'csv';
import Page from '../../../components/Page';
import { useAuth0 } from '../../../utils/Auth0Provider';

const AddNewRecords = () => {
  const { accessToken } = useAuth0();

  const onDrop = useCallback(
    (files) => {
      const reader = new FileReader();

      reader.onabort = () => console.log('file reading was aborted');
      reader.onerror = () => console.log('file reading failed');

      reader.onload = () => {
        csv.parse(reader.result, (err, data) => {
          const userList = [];

          for (let i = 0; i < data.length; i++) {
            const firstName = data[i][0];
            const lastName = data[i][1];
            const email = data[i][2];
            const phoneNumber = data[i][3];
            const newUser = {
              first_name: firstName,
              last_name: lastName,
              email,
              phone_number: phoneNumber,
              do_not_sms: false,
            };

            userList.push(newUser);
          }

          axios.post(`${process.env.REACT_APP_API_URL}/v1/records`, userList, {
            headers: { Authorization: `Bearer ${accessToken}` },
          });
        });
      };

      // read file contents
      files.forEach((file) => reader.readAsBinaryString(file));
    },
    [accessToken]
  );

  return (
    <Page title="Upload new records">
      <Container maxWidth="md">
        <Card>
          <CardContent>
            <Dropzone accept=".csv" onDrop={onDrop}>
              {({ getRootProps, getInputProps }) => (
                <section>
                  <div {...getRootProps()}>
                    <input {...getInputProps()} />
                    <p>Drag-n-drop some files here, or click to select files</p>
                  </div>
                </section>
              )}
            </Dropzone>
          </CardContent>
        </Card>
      </Container>
    </Page>
  );
};

export default AddNewRecords;
